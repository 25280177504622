<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card-actions
          ref="loadingCard"
          no-actions
          title="Informações de local"
          sub-title="Insira os dados de endereço referente ao local do cliente."
        >
          <validation-observer
            ref="regrasClienteEndereco"
            tag="form"
          >
            <b-row>
              <b-col md="6">
                <selecionar-cliente
                  v-if="buscarCliente"
                  :campo-obrigatorio="true"
                  :desabilitar-campo="modo === 'V' ? true : false"
                  :cliente-preenchido="clientePreenchido"
                  @clienteSelecionado="updateClienteSelecionado"
                />
              </b-col>

              <b-col md="6">
                <!-- Nome do local -->
                <validation-provider
                  #default="{ errors }"
                  name="Nome do local"
                  rules="required"
                >
                  <b-form-group
                    label="Nome do local:"
                    label-for="titulo"
                  >
                    <b-form-input
                      id="titulo"
                      v-model="local.titulo"
                      :state="errors.length > 0 ? false : null"
                      :disabled="modo === 'V'"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- CEP -->
                <validation-provider
                  #default="{ errors }"
                  name="CEP"
                  rules="required"
                >
                  <b-form-group
                    label="CEP:"
                    label-for="cep"
                  >
                    <b-input-group>
                      <cleave
                        id="cep"
                        v-model="local.cep"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        :raw="false"
                        :options="options.cep"
                        :state="errors.length > 0 ? false : null"
                        :disabled="modo === 'V'"
                        @input="buscaEnderecoCep()"
                      />
                      <b-input-group-append>
                        <b-button
                          :variant="
                            errors.length > 0 ? 'danger' : 'outline-primary'
                          "
                          :disabled="modo === 'V'"
                          @click="limpaEndereco"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Logradouro -->
                <validation-provider
                  #default="{ errors }"
                  name="Logradouro"
                  rules="required"
                >
                  <b-form-group
                    label="Logradouro:"
                    label-for="logradouro"
                  >
                    <b-form-input
                      id="logradouro"
                      v-model="local.logradouro"
                      :state="errors.length > 0 ? false : null"
                      :disabled="modo === 'V'"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Logradouro Número -->
                <validation-provider
                  #default="{ errors }"
                  name="Número"
                  rules="required"
                >
                  <b-form-group
                    label="Número:"
                    label-for="logradouroNumero"
                  >
                    <b-form-input
                      id="logradouroNumero"
                      v-model="local.logradouroNumero"
                      :state="errors.length > 0 ? false : null"
                      :disabled="modo === 'V'"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Complemento -->
                <b-form-group
                  label="Complemento:"
                  label-for="complemento"
                >
                  <b-form-input
                    id="complemento"
                    v-model="local.complemento"
                    :disabled="modo === 'V'"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <!-- Bairro -->
                <validation-provider
                  #default="{ errors }"
                  name="Bairro"
                  rules="required"
                >
                  <b-form-group
                    label="Bairro:"
                    label-for="bairro"
                  >
                    <b-form-input
                      id="bairro"
                      v-model="local.bairro"
                      :state="errors.length > 0 ? false : null"
                      :disabled="modo === 'V'"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Município -->
                <validation-provider
                  #default="{ errors }"
                  name="Município"
                  rules="required"
                >
                  <b-form-group
                    label="Município: "
                    label-for="municipio"
                  >
                    <b-form-input
                      id="municipio"
                      v-model="local.municipio"
                      :state="errors.length > 0 ? false : null"
                      :disabled="modo === 'V'"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Estado -->

                <validation-provider
                  #default="{ errors }"
                  name="Estado"
                  rules="required"
                >
                  <b-form-group
                    label="Estado:"
                    label-for="estado"
                  >
                    <b-form-input
                      id="estado"
                      v-model="local.estado"
                      :state="errors.length > 0 ? false : null"
                      :disabled="modo === 'V'"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
          <b-row>
            <b-col md="2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                block
                @click="voltarParaLista()"
              >
                Voltar
              </b-button>
            </b-col>
            <b-col
              md="2"
              offset="8"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="modo === 'V'"
                :style="modo === 'V' ? 'cursor: not-allowed;' : null"
                block
                @click="validaEndereco()"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { required, email, min, extend } from "@validations"
import Cleave from "vue-cleave-component"
import useJwt from "@/auth/jwt/useJwt"
import SelecionarCliente from "@/views/global/SelecionarCliente.vue"
import BCardActions from "@/@core/components/b-card-actions/BCardActions.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    Cleave,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    SelecionarCliente,
    BCardActions,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      modo: null, // C-adastrar / E-ditar / V-isualizar
      clientePreenchido: 0, // Código do cliente para função editar
      buscarCliente: false, // Libera campo buscar cliente
      LiberarBuscaCep: false, // Libera função buscar cep
      local: {
        cliente: null,
        titulo: null,
        cep: null,
        logradouro: null,
        logradouroNumero: null,
        complemento: null,
        bairro: null,
        municipio: null,
        estado: null
      },
      options: {
        cep: {
          delimiters: [".", "-"],
          blocks: [2, 3, 3],
          numericOnly: true
        }
      }
    }
  },
  mounted() {
    this.$refs["loadingCard"].showLoading = false
    this.defineModo()
  },
  methods: {
    voltarParaLista() {
      this.$router.push({ name: "consultar-trabalho" })
    },
    async inserirLocal() {
      // console.log("Inserir local")
      this.$refs["loadingCard"].showLoading = true
      const resp = await useJwt
        .inserirLocal(this.local)
        .then(response => {
          this.$refs["loadingCard"].showLoading = false
          return response.data
        })
        .catch(error => {
          this.$refs["loadingCard"].showLoading = false
          console.error("Não foi possível inserir o local", error)
          this.$toast({
            component: ToastificationContent,
            timeout: 2000,
            props: {
              title: "Erro ao inserir local.",
              icon: "XOctagonIcon",
              variant: "danger",
              text: "Não foi possível registrar o local: " + error
            }
          })
        })

      if (resp.sucesso) {
        this.$toast({
          component: ToastificationContent,
          timeout: 2000,
          props: {
            title: "Inserção realizada com sucesso.",
            icon: "SaveIcon",
            variant: "success",
            text: "Os dados do local foram registrados no sistema."
          }
        })

        this.$router.push({
          name: "consultar-trabalho"
        })
      }
    },
    async alterarLocal() {
      delete this.local.idBase
      delete this.local.cliente

      this.$refs["loadingCard"].showLoading = true
      const resp = await useJwt
        .alterarLocal(this.$route.params.idDoLocal, this.local)
        .then(response => {
          this.$refs["loadingCard"].showLoading = false
          return response.data
        })
        .catch(error => {
          this.$refs["loadingCard"].showLoading = false
          console.error("Não foi possível salvar o local", error)
          this.$toast({
            component: ToastificationContent,
            timeout: 2000,
            props: {
              title: "Erro ao alterar local.",
              icon: "XOctagonIcon",
              variant: "danger",
              text: "Não foi possível registrar as alterações: " + error
            }
          })
        })

      if (resp.sucesso) {
        this.$toast({
          component: ToastificationContent,
          timeout: 2000,
          props: {
            title: "Alteração realizada com sucesso.",
            icon: "SaveIcon",
            variant: "success",
            text: "Os novos dados do local foram registrados no sistema."
          }
        })

        this.$router.push({
          name: "consultar-trabalho"
        })
      }
    },
    async buscarLocal() {
      const resp = await useJwt
        .consultarLocal(this.$route.params.idDoLocal)
        .then(response => response.data)
        .catch(error => {
          console.error("Erro na consulta: ", error)
          this.LiberarBuscaCep = true

          this.$toast({
            component: ToastificationContent,
            timeout: 3000,
            props: {
              title: "Erro ao buscar local.",
              icon: "XOctagonIcon",
              variant: "danger",
              text: "Não foi possível identificar o local solicitado: " + error
            }
          })

          this.modo = "V" // Bloqueia campos
        })

      // const resp = await this.$http
      //   .get(`http://localhost:3000/local/${this.$route.params.idDoLocal}`)
      //   .then(response => response)
      //   .catch(error => console.error(error))

      this.$refs["loadingCard"].showLoading = false

      if (resp.sucesso) {
        // console.log("Resp: ", resp)
        this.local = resp.dados

        // No modo editar, libera campo somente, após preenchar data
        this.clientePreenchido = this.local.cliente
        this.buscarCliente = true

        // Libera busca cep somente após preencher dados
        setTimeout(() => {
          this.LiberarBuscaCep = true
        }, 1000)
      }
    },
    defineModo() {
      // O componente pode ser chamado para:
      // C-adastrar / E-ditar / V-isualizar
      // console.log("Rota: ", this.$route.path)
      if (this.$route.path.includes("cadastrar")) {
        this.modo = "C"
        this.buscarCliente = true
        this.LiberarBuscaCep = true // Inicia com busca cep liberada
      } else if (this.$route.path.includes("editar")) {
        this.modo = "E"
      } else {
        this.modo = "V"
      }

      if (this.modo !== "C") {
        this.$refs["loadingCard"].showLoading = true
        this.buscarLocal()
      }

      // console.log("idDoLocal: " + this.$route.params.idDoLocal)
    },
    updateClienteSelecionado(cliente) {
      // console.log("O cliente selecionado foi: ", cliente)
      this.local.cliente = cliente.idDoCliente
    },
    limpaEndereco() {
      this.$refs["loadingCard"].showLoading = true
      this.local.cep = null
      this.local.logradouro = null
      this.local.logradouroNumero = null
      this.local.complemento = null
      this.local.bairro = null
      this.local.municipio = null
      this.local.estado = null
      this.$refs["loadingCard"].showLoading = false
    },
    async buscaEnderecoCep() {
      if (this.local.cep.length === 10 && this.LiberarBuscaCep) {
        this.$refs["loadingCard"].showLoading = true
        const cep = this.local.cep.replace(/[^\d]/g, "")

        const resp = await useJwt
          .getCep(cep)
          .then(response => response.data)
          .catch(response => response.data)

        if (resp) {
          this.local.logradouro = resp.logradouro
          this.local.bairro = resp.bairro
          this.local.municipio = resp.localidade
          this.local.estado = resp.uf
          this.$refs["loadingCard"].showLoading = false
        }
        //  Faz o tratamento de erro na tela
      }
    },
    validaEndereco() {
      return new Promise((resolve, reject) => {
        this.$refs.regrasClienteEndereco
          .validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              throw new Error(
                "Não passou na validação. Verificar dados obrigatórios!"
              )
            }
          })
          .then(response => {
            this.modo === "E"
              ? this.alterarLocal()
              : this.modo === "C"
              ? this.inserirLocal()
              : null
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              timeout: 3000,
              props: {
                title: "Erro ao salvar local.",
                icon: "XOctagonIcon",
                variant: "danger",
                text: error.toString()
              }
            })
          })
      })
    }
  }
}
</script>
<style>
[dir] .card-subtitle {
  margin-top: 0;
}
</style>
